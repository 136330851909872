import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "./default"
import NewsArticles from "../components/news-articles"

const NewsTemplate = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <NewsArticles />
    </DefaultLayout>
  )
}

export default NewsTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        quote
        quoteAuthor
        image {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
